import React from 'react';
import ActivityTable from './widgets/ActivityTable';
import Sidebar from './widgets/Sidebar';

const DashboardPage = () => {
    const handleRegisterAccount = () => {
        window.location.href = '/register-subscriber';
    }
    return (
        <div className='mb-8'>
            <Sidebar />
            <div className="py-2 ml-64 sm:px-4">
                <div className="mt-4 lg:px-12 sm:px-4 md:px-6">
                    <p className='text-2xl' style={{ fontFamily: "Red Hat Display" }}>Admin's Dashboard</p>
                    <div className='flex flex-col gap-2 mt-4'>
                        <p className="text-lg font-bold">User Activities</p>
                        <p>View and filter activities in CONSOLE-D.</p>
                        <ActivityTable />
                    </div>
                    <div className="flex flex-col w-2/5 gap-2 mt-8">
                        <p className="text-lg font-bold">Register free account</p>
                        <p>Create new user accounts while simultaneously assigning them to predefined subscription plans.</p>
                        <button className='w-48 px-4 py-2 mt-4 font-bold text-white rounded-md bg-orange tetx-sm' onClick={handleRegisterAccount}>Register account</button>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default DashboardPage;